import React from "react";
import { Button, Typography } from "@mui/material";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import screenshot from "../../images/instascreenshot.png";
import pdf from "./pdf.pdf";
import { saveAs } from "file-saver";
import { Box } from "@mui/system";

const Press = () => {
    const saveFile = () => {
        saveAs(
        "https://drive.google.com/u/1/uc?id=1O-CZDy_40m8Ho4RTt25HLdYknz_rtd70&export=download",
        "newsletter.pdf"
        );
    };
    return ( 
        <div style={{margin: 16}}>
            <Typography variant="h2" sx={{ color: "white", fontFamily: "STIX TWO TEXT", fontWeight: 600 }}>Press</Typography>
            <Typography variant={isMobile ? ("h4") : ("h3")} align="center" sx={{ color: "#7cc4dc", fontFamily: "nunito" }}>Follow our instagram: @qismunqa</Typography>
            <img src={screenshot} alt="insta" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: isMobile ? ("90%") : ("50%") }} />
            <Typography variant={isMobile ? ("h4") : ("h3")} align="center" sx={{ color: "#7cc4dc", fontFamily: "nunito" }}>Newsletter:</Typography>
            <BrowserView>
                <iframe src={`${pdf}#view=fitH`} title="testPdf" style={{ height: "1000px", width: "100%" }} />
            </BrowserView>
            <MobileView>
                <Typography sx={{ color: "#7cc4dc", fontFamily: "nunito" }} align="center">Looks like your on mobile you will have to download the pdf</Typography>
                <Box textAlign='center'>
                    <Button variant="contained" onClick={saveFile} sx={{ fontFamily: "nunito" }}>Download</Button>
                </Box>
            </MobileView>
        </div>
     );
}
 
export default Press;